<template>
	<div>
		<PopupCommon ref="lockSongPopup" customClassName="textarea-modal" title=" " :visible.sync="visible" :width="'586px'">
			<div :slot="'title'" class="pop-title4">{{$t('recommend.buysubscribe')}}</div>
			<div :slot="'content'" class="pop-content4">
				<div class="subscribetime">{{$t('recommend.subscribetime')}}</div>
				<el-collapse v-model="activeNames"
				             style="margin-left:30px;" :accordion="true" @change="changeColl">
				      <el-collapse-item :name="index" v-for="(item,index) in timeList" :key="index">
				        <template slot="title">
				          <p class="title">{{item.subscription}}</p>
				        </template>
				        <div class="row"
				             style="margin-top:18px;" :class="{ 'active': currentTime == index }">
				          <span style="margin-left:8px">{{item.details}}</span>
				        </div>
				      </el-collapse-item>
				  </el-collapse>
				<div class="pay-methods">
					<div class="pay-methods-title">{{this.$t("paymethodtext")}}</div>
					<div class="pay-methods-bottom">
						<div class="pay-lf">
							<!-- <div class="pay-botton">微信</div>
							<div class="pay-botton">支付宝</div> -->
							<div class="pay-botton-wrap" v-for="(item, index) in BuyList" :key="index">
								<el-button class="pay-botton" :class="{ 'active': currentbuy == index }"
									@click="Buybtn(index, item.id)">{{ item.name }}</el-button>
							</div>
							
						</div>
						<div class="pay-rg">
							<!-- <img :src="codeImg" > -->
							<img class="code-img" v-if="codeImg" :src="codeImg" >
							<img class="logo-img" v-if="!codeImg && themeStyle == 'dark'" src="../../assets/images/main/logo-white.png" >
							<img class="logo-img" v-if="!codeImg && themeStyle == 'default'" src="../../assets/images/main/logo-dark.png" >
						</div>
					</div>
					
				</div>
				
				
				
				<!-- <div class="content-top">
					<pay-immediately-top :type="1" :orderInfo="orderInfo" @changeDay="changeDay"></pay-immediately-top>
				</div>
				
				<pay-immediately-bottom :orderInfo="payInfo" :CountDown="CountDown" @wechatPay="wechatPay" @alipayPay="alipayPay"></pay-immediately-bottom> -->
			</div>
			<div :slot="'footer'" class="pop-footer4">
				<div class="footer-lf">
					<div class="price-wrap">
						<div class="price-title">{{$t("price")}}</div>
						<div class="price-text">￥{{price}}</div>
					</div>
				</div>
				<div class="footer-rg">
					<div v-if="themeStyle == 'default'" class="customr-service" @click="demoCustomerService">
						<a
						  href="http://kf.7keymusic.com/addons/kefu"
						  target="_blank"
						>
							<img src="@/assets/images/recommend/custom-default.png" alt="">
						</a>
					</div>
					<div v-if="themeStyle == 'dark'" class="customr-service" @click="demoCustomerService">
						<a
						  href="http://kf.7keymusic.com/addons/kefu"
						  target="_blank"
						>
							<img src="@/assets/images/recommend/custom-dark.png" alt="">
						</a>
					</div>
					<div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
				</div>
				
			</div>
		</PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
	import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
	import { musicInfo,buyLockPrice,logo } from "@/api/Home"
	import { getCheckOrder,subscriptList,buyBgm } from "@/api/Mine";
	export default {
		props: {
			orderInfo: {
				type: Object,
				default(){
					return {}
				}
			},
			payInfo: {
				type: Object,
				default(){
					return {}
				}
			}
		},
		components: {
			PopupCommon,
			PayImmediatelyBottom,
			PayImmediatelyTop
		},
		created() {
			// this.getMusicInfo()
			// console.log(this.orderInfo,'payInfo')
			this.getSubscriptList();
			// this.getLogoImg()
		},
		data(){
			return {
				currentTime: 0,
				price: "0",
				currentbuy: -1,
				BuyList: [
					{
						name: this.$t("recommend.payweixin")
					},
					{
						name: this.$t("recommend.payapliy")
					}
				],
				// on: "开",
				// light: 100,
				// devName: '虎牌双色灯',
				// prodName: '双色灯',
				// devId: 'M000012',
				// modelId: '双色灯5045',
				activeNames: '1' ,
				visible: false,
				payType: '',//支付方式 1支付宝 2微信
				day: '',//锁定时间
				musicId: '',
				// orderInfo: {
				// 	avatar: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg13.51tietu.net%2Fpic%2F2020010611%2Fnfptpll3xzknfptpll3xzk.jpeg&refer=http%3A%2F%2Fimg13.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670075622&t=7a0a6d98360a03dc014ab446419bbf92',
				// 	name: 'Clarence Walters',
				// 	authorizedTerritory: '大陆',
				// 	authorizationType: '灌录权（灌录权、传播权、复制权）',
				// 	orderSn: '1234567890',
				// 	orderMoney: '30',
				// 	timeList: [{
				// 			time: '3'
				// 		},
				// 		{
				// 			time: '5'
				// 		},
				// 		{
				// 			time: '17'
				// 		}
				// 	]
				// },
				// payInfo: {
				// 	codeImg: 'https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500',
				// 	time: '29分42秒',
				// 	money: '30',
				// 	isWechat: '1', //是否支持微信支付 0不支持 1支持
				// 	isApliy: '1', //是否支持支付宝支付 0不支持 1支持
				// 	isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
				// 	corporateName: '名称：沈阳梯基音乐文化传媒有限公司',
				// 	bankDeposit: '建设银行沈阳南京街支行',
				// 	bankAccount: '21050110084600000051'
				
				// },
				locksetinterval: "",
				checksetinterval: "",
				themeStyle: '', //主题 default浅色 dark深色
				timeList: {},
				codeImg: '',
				orderSn: '0',
				paysuccess: false
			}
		},
		methods: {
			// 获取logo图片
			getLogoImg() {
				logo()
					.then((res) => {
						// console.log(res, '获取logo图片');
						this.codeImg = res
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			changeColl(e){
				console.log(e,'jjjjjjjjjjjjjjjjjjjjj')
				this.currentTime = e
				this.price = this.timeList[e].price
			},
			Buybtn(index, id){
				this.currentbuy = index
			},
			getSubscriptList(){
				subscriptList({}).then((res) => {
					console.log(res, 'getSubscriptList');
					this.timeList = res.data
					this.price = this.timeList[1].price
				})
				.catch((err) => {
					// console.log(err, '获取banner失败');
				});
			},
			getMusicInfo(){
				musicInfo({}).then((res) => {
					console.log(res, '预约价格锁定表');
					this.orderInfo.timeList = res.data.list
				})
				.catch((err) => {
					// console.log(err, '获取banner失败');
				});
			},
			getPayCode(musicId, day, way) {
			  this.locksetinterval && clearTimeout(this.locksetinterval);
			  if (day.length === 0) {
			  } else if (way.length === 0) {
			  } else {
			    this.locksetinterval = setTimeout(() => {
			      let params = {};
			      params.musicId = musicId;
			      params.day = day;
			      params.payType = way;
			
			      buyLockPrice(params).then((res) => {
					  this.orderInfo.orderSn = res.data.order_id
					  this.orderInfo.orderMoney = res.data.price
					  this.payInfo.codeImg = res.data.qr_code
					  this.payInfo.money = res.data.price
			        this.$store.state.pay_code = res.data.qr_code;
			        this.$store.state.pay_price = res.data.price;
			        this.pay = true;
			        let data = {};
			        data.order_id = res.data.order_id;
			        this.checkorder = data.order_id;
			        // this.checksetinterval && clearInterval(this.checksetinterval);
			        // this.checksetinterval = setInterval(() => {
			        //   getCheckOrder(data).then((res) => {
			        //     if (res.status === 1) {
			        //       this.paysuccess = true;
			        //       this.lockDialog = false;
			        //       this.checksetinterval && clearInterval(this.checksetinterval);
			        //       this.paysuccess_data = res.data;
			        //     }
			        //   });
			        // }, 1500);
			      });
			    }, 300);
			
			    //
			  }
			},
			demoCustomerService(){
				this.$refs.lockSongPopup.close()
			},
			demoFinishPay(){
				let params = {}
				params.payType = this.currentbuy + 1
				params.s_id = this.timeList[this.currentTime].id
				buyBgm(params).then((res) => {
					console.log(res,'kkkkkkkkkkkkkkkkkk')
					this.codeImg = res.data.qr_code;
					this.orderSn = res.data.order_id
					let data = {};
					data.order_id = this.orderSn
					getCheckOrder(data).then((res) => {
					    if (res.status === 1) {
					      this.paysuccess = true;
					      this.lockDialog = false;
					      this.checksetinterval && clearInterval(this.checksetinterval);
					      this.paysuccess_data = res.data;
						  this.$refs.lockSongPopup.close()
						  this.$router.push("/personalCenter/MyPurchase/Purchased");
					    }
					  }).catch((err) => {
						// console.log(err, '获取banner失败');
					});
				})
				
				
			},
			open(){
				console.log('lockSongOpen')
				this.themeStyle = localStorage.getItem("theme")
				this.$refs.lockSongPopup.open()
			},
			wechatPay(data){
				console.log(data,'wechatPay')
				this.payType = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			},
			alipayPay(data){
				console.log(data,'alipayPay')
				this.payType = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			},
			changeDay(data){
				console.log(data,'changeDay')
				this.day = data
				if(this.payType != '' && this.day != ''){
					this.getPayCode(this.orderInfo.musicId,this.day,this.payType)
				}
			}
			// close(){
			// 	this.$refs.lockSongPopup.close()
			// }
		}
	}
</script>

<style lang="less" scoped>
	::v-deep .el-dialog {
		margin-top: 9vh !important;
	}
	::v-deep .el-collapse-item{
		// background-color: @buttonBgColor;
		color: @recommendMusicianListTextColor;
		border: 1px solid @buttonBorderBgColor;
		margin-bottom: 20px;
		border-radius: 6px;
	}
	::v-deep .el-collapse{
		background-color: @buttonBgColor;
		color: @recommendMusicianListTextColor;
		margin-left: 0px!important;
		border-bottom: none;
		border-top: none;
	}
	// ::v-deep .el-collapse-item .is-active{
	// 	background-color: @buttonBgColor;
	// 	// color: @primaryColor;
	// 	color: #3278FF;
	// 	// border-bottom: none;
	// 	// border-top: none;
	// 	// border: 1px solid @truebuttonBorderBgColor;
	// 	border: 1rpx solid #3278FF;
	// }
	::v-deep .is-active{
				border: 1rpx solid #3278FF !important;
	}
	
	::v-deep .el-collapse-content {
		background-color: @buttonBgColor;
		border-bottom: none;
		border-top: none;
		
	}
	::v-deep .el-collapse-item__wrap {
		background-color: @buttonBgColor;
		border-bottom: none;
		border-top: none;
		
	}
	::v-deep .el-collapse-item__header{
		background-color: @buttonBgColor;
		border-bottom: none;
		border-top: none;
		height: 60px;
		padding-left: 8px;
		color: @recommendMusicianListTextColor;
	}
	::v-deep .el-collapse-item__header.is-active{
		color: #3278FF;
	}
	::v-deep .el-collapse-item__arrow .el-icon-arrow-right{
		color: @recommendMusicianListTextColor;
	}
	::v-deep .el-collapse-item__arrow{
		color: @recommendMusicianListTextColor;
	}
	
	// 锁定demo弹窗
	.pop-title4 {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}
	
	.pop-content4 {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;
		.subscribetime{
			margin-bottom: 14px;
		}
		// .title {
		//   font-size: 14px;
		//   font-family: Microsoft YaHei;
		//   // font-weight: bold;
		//   color: @primaryColor;;
		//   padding-bottom: 17px;
		//   padding-top: 17px;
		// }
		// ::v-deep .el-collapse-item__header.is-active {
		//   border-bottom: 1px solid #ebeef5;
		// }
		// ::v-deep .el-collapse-item__wrap {
		//   border: none;
		// }
		p {
		  margin: 0;
		  padding: 0;
		}
		.row {
		  overflow: hidden;
		  margin-bottom: 22px;
		  color: @primaryColor;
		  &.active{
			  color: #3278FF;
		  }
		}
		.row .col_left {
		  float: left;
		  width: 420px;
		}
		.row .col_right {
		  float: left;
		}
		.pay-methods{
			.pay-methods-title{
				margin-bottom: 14px;
			}
			.pay-methods-bottom{
				display: flex;
				justify-content: space-between;
				.pay-lf {
					display: flex;
					.pay-botton-wrap{
						
						.pay-botton{
							margin-right: 20px;
							width: 70px;
							height: 40px;
							color: var(--buttonTextColor);
							background-color: var(--buttonBgColor);
							border-color: var(--buttonBorderBgColor);
							display: flex;
							justify-content: center;
							&.active {
								color: var(--truebuttonTextColor);
								background-color: var(--truebuttonBgColor);
								border-color: var(--truebuttonBorderBgColor);
							}
						}
						.pay-botton:last-child{
							// margin-right: 0px;
							
						}
					}
					
					
				}
				.pay-rg{
					.logo-img{
						width: 158px;
						height: 68px;
						// border-radius: 6px;
						margin-right: 20px;
					}
					.code-img{
						width: 190px;
						height: 190px;
						border-radius: 6px;
						margin-right: 20px;
					}
					// img{
					// 	width: 140px;
					// 	height: 140px;
					// 	border-radius: 6px;
					// 	margin-right: 20px;
					// }
				}
			}
		}
	}
	
	.pop-footer4 {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: space-between;
		padding-right: 20px;
		.footer-lf{
			margin-left: 20px;
			.price-wrap{
				display: flex;
				align-items: center;
				.price-title{
					color: @recommendMusicianListTextColor;
					margin-right: 10px;
					font-size: 14px;
				}
				.price-text {
					color: #3278FF;
					font-size: 16px;
					font-weight: 500;
				}
			}
		}
		.footer-rg{
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: space-between;
			// padding-right: 20px;
			.customr-service{
				// width: 102px;
				// height: 36px;
				border-radius: 4px;
				border: 1px solid @recommendMusicianListTextColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
				width: 40px;
				height: 36px;
				border-radius: 4px;
				cursor: pointer;
				// border: 1px solid #FFFFFF;
				img{
					width: 16px;
					height: 16px;
				}
				&:hover{
					// border: 1px solid @recommendMusicianListTextColor;
					position: relative;
					&:after{
						position: absolute;
						content: '联系版权客服';
						left: -50%;
						bottom: -30px;
						background: red;
						color: green;
						width: 92px;
						height: 27px;
						background: rgba(255,255,255,0.7);
						box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
						border-radius: 4px;
						font-size: 12px;
						color: #222426;
						line-height: 27px;
						display: flex;
						justify-content: center;
					}
				}
			}
			.confirm{
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;
				cursor: pointer;
			}
		}
		
		
	}
</style>