<template>
	<div class="box">
		<div class="box-tp">
			<div class="box-lf">
				<div class="photo" style="position: relative;">
					<!-- <img :src="DemoseaList.image" class="coverimg" style="position: absolute;z-index: 11;" /> -->
					<!-- 播放图标 开始 -->
					<div class="play-wrap">
						<!-- <div class="play">
							<i class="el-icon-caret-right" color="red" size="50"></i>
						</div> -->
						
						<div class="play" @click="pause()"
						     v-if="$store.state.musicInfo.playStatus && DemoseaList.id == $store.state.musicInfo.id">
						  <Icon name="icon-7key-music24gf-pause2" class="icon-play" color="#3370FF"/>
						</div>
						<div class="play" @click="play()"
						     v-else>
						  <Icon name="icon-7key-musicplayfill" class="icon-play" color="#3370FF"/>
						</div>
					</div>
					<!-- <div v-if="show == true" class="play">
					</div> -->
					<!-- 播放图标 结束 -->
				</div>
				<div>
					<div class="title">{{ DemoseaList.title }}</div>
					<!-- <div class="time">
						<img src="../../assets/images/musiclibary/time_dark.png" class="time-p" />
						{{ DemoseaList.duration }}
					</div> -->
					<div class="box-bottom">
						<div class="tabs" style="display: flex;">
							<div class="tabs-item" v-for="(item, index) in DemoseaList.genrelist" :key="index">
								<div class="tabs-p">{{ item }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box-rg">
				<!-- <div class="box-top">
					<div class="username">{{ DemoseaList.username }}</div>
					<div @click="toDetail(DemoseaList.creatorId)" class="user-photo">
						<img :src="DemoseaList.creatorHead" class="user-photo-img" />
					</div>
				</div> -->
				<div class="box-bottom">
					<!-- <div v-if="$store.state.identity != 3" @click="lockSong(DemoseaList)" class="locking">
						<Icon name="icon-7key-musicsuoding" theme-color="iconColorAll" width="18" height="18"></Icon>
					</div> -->
					<div class="share" v-clipboard:copy="DemoseaList.url" v-clipboard:success="onCopy" v-clipboard:error="onError">
						<Icon name="icon-7key-musicfenxiangfabu" theme-color="iconColorAll" width="18" height="18"></Icon>
					</div>
					<div @click="uploadfilesMyClaim(DemoseaList)" class="collect">
						<Icon name="icon-7key-musicxiazai" theme-color="iconColorAll" width="18" height="18"></Icon>
					</div>
					<div class="collect" v-if="DemoseaList.isFavorite == 0" @click="liking(DemoseaList.id)">
						<!-- <Icon name="icon-7key-musicxingxing" theme-color="iconColorAll" width="18" height="18">
						</Icon> -->
						<Icon name="icon-7key-musicxingxing" v-if="themeStyle == 'default'" color="#666666" width="18" height="18">
						</Icon>
						<Icon name="icon-7key-musicxingxing" v-if="themeStyle == 'dark'" color="#FFFFFF" width="18" height="18">
						</Icon>
					</div>
					<div class="collect" v-if="DemoseaList.isFavorite == 1" @click="liked(DemoseaList.id)">
						<Icon name="icon-7key-musictianchongwujiaoxing" color="#FEC002" width="18" height="18"></Icon>

					</div>

					<div class="buy" v-if="$store.state.identity != 3">
						<div class="buy-p" @click="lockSong(DemoseaList)">{{ $t('recommend.buyNow') }}</div>
						<!-- <el-dialog title="立即购买" width="55%" :visible.sync="centerDialogVisible" center>
							<BuySong></BuySong>
						</el-dialog> -->
					</div>
				</div>
			</div>
		</div>
		

		<!-- 立即支付 弹窗 -->
		<!-- <PopupCommon ref="popupcommon" customClassName="textarea-modal" title=" " :visible.sync="visible" :width="'986px'">
			<div :slot="'title'" class="pop-title">立即支付</div>
			<div :slot="'content'" class="pop-content">
				<div class="content-top">
					<pay-immediately-top :orderInfo="orderInfo"></pay-immediately-top>
				</div>
				
				<pay-immediately-bottom :orderInfo="payInfo"></pay-immediately-bottom>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div v-if="themeStyle == 'default'" class="cancel" @click="payCustomerService">
					<img src="@/assets/images/recommend/custom-default.png" alt="">
				</div>
				<div v-if="themeStyle == 'dark'" class="cancel" @click="payCustomerService">
					<img src="@/assets/images/recommend/custom-dark.png" alt="">
				</div>
				<div class="confirm" @click="payFinishPay">完成支付</div>
			</div>
		</PopupCommon> -->

		<!-- 锁定demo 弹窗 -->
		<!-- <buySubscribePopup ref="lockSongPopup" :orderInfo="orderInfo" :payInfo="payInfo"></buySubscribePopup> -->
		<!-- <PopupCommon ref="lockSongPopup" customClassName="textarea-modal" title=" " :visible.sync="visible" :width="'986px'">
			<div :slot="'title'" class="pop-title">{{$t('recommend.payImmediately')}}</div>
			<div :slot="'content'" class="pop-content">
				<div class="content-top">
					<pay-immediately-top :type="1" :orderInfo="orderInfo"></pay-immediately-top>
				</div>
				
				<pay-immediately-bottom :orderInfo="payInfo"></pay-immediately-bottom>
			</div>
			<div :slot="'footer'" class="pop-footer">
				<div v-if="themeStyle == 'default'" class="cancel" @click="demoCustomerService">
					<img src="@/assets/images/recommend/custom-default.png" alt="">
				</div>
				<div v-if="themeStyle == 'dark'" class="cancel" @click="demoCustomerService">
					<img src="@/assets/images/recommend/custom-dark.png" alt="">
				</div>
				<div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
			</div>
		</PopupCommon> -->
	</div>
</template>

<script>
// import BuySong from "@/components/popup/BuySong.vue"
import PopupCommon from "@/components/popup/PopupCommon.vue"
import buySubscribePopup from "@/components/popup/buySubscribePopup.vue"
import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
import {
	getLiking,
	getLiked
} from "@/api/Mine";

export default {
	name: "DemoSea",
	props: {
		DemoseaList: {
			type: Object,
			default() {
				return {

				}
			}
		}
	},
	data() {
		return {
			show: false,//控制播放图标显示 false 不显示  true 显示
			buy: 0,
			visible: false,
			centerDialogVisible: false,
			orderInfo: {
				avatar: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg13.51tietu.net%2Fpic%2F2020010611%2Fnfptpll3xzknfptpll3xzk.jpeg&refer=http%3A%2F%2Fimg13.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1670075622&t=7a0a6d98360a03dc014ab446419bbf92',
				name: 'Clarence Walters',
				authorizedTerritory: this.$t('recommend.authorizedTerritoryText'),
				authorizationType: this.$t('authorizationTypeText'),
				orderSn: '1234567890',
				orderMoney: '30',
				timeList: [
					{
						time: '3'
					},
					{
						time: '5'
					},
					{
						time: '17'
					}
				]
			},
			payInfo: {
				codeImg: 'https://img2.baidu.com/it/u=1989038259,1842360401&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500',
				// time: '29分42秒',
				money: '30',
				isWechat: '1',//是否支持微信支付 0不支持 1支持
				isApliy: '1',//是否支持支付宝支付 0不支持 1支持
			},
			themeStyle: '',//主题 default浅色 dark深色
		};
	},
	components: {
		// BuySong,
		buySubscribePopup,
		PopupCommon,
		PayImmediatelyBottom,
		PayImmediatelyTop
	},
	computed: {},
	created() {
	console.log(this.DemoseaList,this.$store.state.musicInfo,'//////////////');
	},
	mounted() {
		const that = this;
		window.addEventListener("setItemEvent", function (e) {
			if((e.newValue === 'dark') || (e.newValue === 'default')){
				console.log(e.newValue)
				that.themeStyle = e.newValue;
			}
			// console.log(e.newValue);
			
		});
		this.themeStyle = localStorage.getItem("theme");
	},
	watch: {},
	methods: {
		// 下载编曲
		uploadfilesMyClaim(item) {
			console.log("item ",item);
			this.uploadBeat(item)
		},
		downloadFile(content, filename) {
			var a = document.createElement("a");
			var blob = new Blob([content]);
			var url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
			// this.$refs.claimBeatPopup.close()
		},
		ajax(url, callback, options) {
			console.log(url, callback, options,window.URL,window.webkitURL,'ajax')
			window.URL = window.URL || window.webkitURL;
			var xhr = new XMLHttpRequest();
			xhr.open("get", url, true);
			if (options.responseType) {
				xhr.responseType = options.responseType;
			}
			xhr.onreadystatechange = function() {
				if (xhr.readyState === 4 && xhr.status === 200) {
					callback(xhr);
				}
			};
			xhr.send();
		},
		// 下载编曲
		uploadBeat(file) {
			console.log(file,'uploadBeat')
			var url = file.url;
			console.log(file);
			let _this = this;
			this.ajax(
				url,
				function(xhr) {
					// var filename = file.title + "." + file.format; // 自定义文件名+后缀
					var filename = file.title + ".mp3"; // 自定义文件名+后缀
					_this.downloadFile(xhr.response, filename);
				}, {
					responseType: "blob",
				}
			);
		},
		onCopy() {
		  this.$message({
		    type: "success",
		    message: this.$t('recommend.copysuccess'),
		  });
		},
		onError() {
		  this.$message({
		    type: "error",
		    message: this.$t('recommend.copyfaild'),
		  });
		},
		// 鼠标移入事件
		mouseover() {
			// console.log('mouseover')
			this.show = true
		},
		// 鼠标移出事件
		mouseout() {
			// console.log('mouseout')
			this.show = false
		},
		play() {
		  this.$set(this.DemoseaList, 'playStatus', true)
		  this.show = false
		  this.$store.commit('changeMusicInfo', this.DemoseaList)
		  console.log('播放:', this.DemoseaList)
		  this.$store.state.playShow = true;
		  console.log('/////////////')
		},
		pause() {
		  this.$set(this.DemoseaList, 'playStatus', false)
		  this.show = false
		  this.$store.commit('changeMusicInfo', this.DemoseaList)
		  console.log('暂停:', this.DemoseaList)
		},
		// 添加喜欢
		liking(musicId) {
			this.$emit('liking', musicId)
			// getLiking({
			// 		musicId: musicId,
			// 		
			// 	}).then((res) => {
			// 		console.log(res, '添加喜欢成功');
			// 		if (res.status == 1) {
			// 			this.$message.success(res.msg)
			// 		} else {
			// 			this.$message.error(res.msg)
			// 		}
			// 		this.getTopMusicList()
			// 	})
			// 	.catch((err) => {
			// 		console.log(err, '添加喜欢失败');
			// 	});
		},
		// 取消喜欢
		liked(musicId) {
			this.$emit('liked', musicId)
			// 	getLiked({
			// 			musicId: musicId
			// 		}).then((res) => {
			// 			console.log(res, '取消喜欢成功');
			// 			if (res.status == 1) {
			// 				this.$message.success(res.msg)
			// 			} else {
			// 				this.$message.error(res.msg)
			// 			}
			// 			this.getTopMusicList()
			// 		})
			// 		.catch((err) => {
			// 			console.log(err, '取消喜欢失败');
			// 		});
		},
		// 打开立即支付弹窗
		payImmediately(item) {
			// this.themeStyle = localStorage.getItem("theme")
			// console.log(this.themeStyle)
			// this.$refs.popupcommon.open()
			this.$emit('buyImmidately',item)
		},
		// 立即支付弹窗 客服
		payCustomerService() {
			this.$refs.popupcommon.close()
		},
		// 立即支付弹窗 完成支付
		payFinishPay() {
			this.$refs.popupcommon.close()

		},
		// 打开锁定demo弹窗
		lockSong(item) {
			this.themeStyle = localStorage.getItem("theme")
			console.log(this.themeStyle)
			this.$emit('lockSong', item)
			// this.$refs.lockSongPopup.open()
		},
		// 锁定demo弹窗 客服
		demoCustomerService() {
			this.$refs.popupcommon.close()
		},
		// 锁定demo弹窗 立即支付
		demoFinishPay() {
			this.$refs.popupcommon.close()
		},
		changeLang(lang) {
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
		},
		// 跳转到详情页
		toDetail(id) {
			console.log(id, 'id')
			this.$emit('toDeatil', id)
			// this.$router.push({
			// 	path: '/MusicianPersonalCenterFinished'
			// })
		},
	},
};
</script>

<style scoped lang="less">
	
.box {
	width: 100%;
	// height: 138px;
	background: @demoitemBGC;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	// align-items: center;
	justify-content: center;
	margin-top: 20px;
	// min-height: 190px;

	.box-tp {
		display: flex;
		align-items: center;
		padding: 16px 0;
		// margin-top: 20px;

		.box-lf {
			display: flex;
			// width: 600px;
			flex: 1;
			// height: 100px;
			// margin: auto;

			.photo {
				width: 76px;
				height: 76px;
				border-radius: 6px;
				margin-left: 16px;
				margin-right: 16px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				.play-wrap {
					// width: 96px;
					// height: 96px;
					// border-radius: 6px;
					// margin-left: 16px;
					// background-color: rgba(255, 255, 255, 0.5);
					// position: absolute;
					// z-index: 33;
					display: flex;
					align-items: center;
					justify-content: center;

					.play {
						color: #3370FF;
						font-size: 40px;
						width: 45px;
						height: 45px;
						box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						background: RGBA(169, 173, 175, 0.8);
						z-index: 33;
						.icon-play{
							margin-top: -6px;
						}
						
					}
				}

				.coverimg {
					width: 96px;
					height: 96px;
					border-radius: 6px;
					// margin-left: 16px;
				}
			}

			.title {
				width: 100%;
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: @demoitemsongnameColor;
				line-height: 22px;
				margin-bottom: 10px;
				overflow: hidden; //超出的文本隐藏
				text-overflow: ellipsis; // 溢出用省略号 
				// white-space: nowrap; //溢出不换行 
				-webkit-line-clamp: 1; //控制显示的行数
				-webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式)
				display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示 

			}

			.time {
				// width: 32px;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: @demoitemsongMusicianColor;
				line-height: 17px;
				display: inline;

				.time-p {
					width: 12px;
					height: 12px;
				}
			}


		}

		.box-rg {
			// width: 300px;
			flex: 1;
			// height: 100px;
			display: flex;
			flex-direction: column;
			padding-right: 16px;
			align-items: flex-end;

			.box-top {
				width: 150px;
				height: 50px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-left: auto;

				.username {
					width: 100px;
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: @demoitemsongMusicianColor;
					line-height: 17px;

				}

				.user-photo {
					width: 34px;
					height: 34px;
					border-radius: 20px;
					cursor: pointer;

					.user-photo-img {
						width: 34px;
						height: 34px;
						border-radius: 20px;
					}

				}
			}

			.box-bottom {
				width: 260px;
				height: 50px;
				display: flex;
				justify-content: flex-end;
				// padding-right: 10px;
				align-items: center;
				margin-left: auto;


				.locking {
					width: 34px;
					height: 34px;
					border-radius: 20px;
					background: @demoitemtabsBGColor;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.locking-p {
						width: 18px;
						height: 18px;
					}

				}

				.share {
						// width: 34px;
					// height: 34px;
					border-radius: 20px;
					background: @demoitemtabsBGColor;
					// display: flex;
					// justify-content: center;
					// align-items: center;
					// margin-left: 12px;
					cursor: pointer;
					padding: 8px 8px 5px 7px;
					// width: 34px;
					// height: 34px;
					// border-radius: 20px;
					// background: @demoitemtabsBGColor;
					// display: flex;
					// justify-content: center;
					// align-items: center;
					// margin-left: 12px;
					// cursor: pointer;

					.share-p {
						width: 18px;
						height: 18px;
					}
				}

				.collect {
					width: 34px;
					height: 34px;
					border-radius: 20px;
					background: @demoitemtabsBGColor;
					margin-left: 12px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.collect-p {
						width: 18px;
						height: 18px;
					}

				}

				.buy {
					width: 88px;
					height: 36px;
					background: #3370ff;
					border-radius: 6px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 18px;
					cursor: pointer;

					.buy-p {
						width: 56px;
						height: 20px;
						font-size: 14px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #ffffff;
						line-height: 20px;
					}
				}
			}
		}
	}


	.box-bottom {
		.tabs {
			// width: 450px;
			height: 24px;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			// margin-left: 11px;

			.tabs-item {
				// max-width: 200px;
				white-space: nowrap;
				height: 24px;
				background: @demoitemtabsBGColor;
				border-radius: 4px;
				justify-content: center;
				align-items: center;
				display: flex;
				margin-right: 5px;
				padding: 0 5px 0 5px;
				margin-bottom: 8px;
			}

			.tabs-p {
				// width: 48px;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				opacity: 0.6;
				color: @demoitemtabsColor;
				line-height: 17px;
			}
		}
	}

	// 登录成功弹窗
	.pop-title {
		height: 68px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 0 25px;
		color: @recommendMusicianListTextColor;
	}

	.pop-content {
		// max-height: 86px;
		padding: 24px;
		border-bottom: 1px solid @demoitemtabsBGColor;
		padding: 25px;
		color: @recommendMusicianListTextColor;
		line-height: 22px;

		.content-top {
			margin-bottom: 24px;
		}
	}

	.pop-footer {
		display: flex;
		align-items: center;
		height: 68px;
		justify-content: flex-end;
		padding-right: 20px;

		.cancel {
			// width: 102px;
			// height: 36px;
			border-radius: 4px;
			border: 1px solid @recommendMusicianListTextColor;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: @popupCancelTextColor;
			margin-right: 44px;
			background: @popupCancelBgColor;
			width: 40px;
			height: 36px;
			border-radius: 4px;

			// border: 1px solid #FFFFFF;
			img {
				width: 16px;
				height: 16px;
			}
		}

		.confirm {
			width: 102px;
			height: 36px;
			background: #3278FF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			color: #FFFFFF;

		}
	}
}
</style>
