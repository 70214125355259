<template>
	<div class="out-back-demo">
		<SelectSearchTabs v-if="$store.state.searchSingerList.length > 0 ||
              $store.state.searchStyleList.length > 0 ||
              $store.state.searchMusicalInstrumentsList.length > 0 ||
              $store.state.searchMoodList.length > 0 ||
              $store.state.searchSpeedList.length > 0" />
		<div class="listbox" v-loading="loading">
			<div v-for="(item, index) in DemoseaList" :key="index">
				<BGMDemo :DemoseaList="item" @liking="liking" @liked="cancelLike" @lockSong="lockSong" @buyImmidately="buyImmidately" type='isBuy'></BGMDemo>
			</div>
			<div class="emptyView">
				<el-empty v-if="isEmpty == true" :image-size="100"></el-empty>
			</div>
			<!-- <audio
      ref="chdMuisc"
      :src="url"
      controls="controls"
      controlsList="nodownload nofullscreen noremoteplayback"
    ></audio> -->
		</div>
		<div class="more-wrap" v-if="isEmpty == false">
			<div v-if="(nothing == false) && (numMusician > params.pageSize)" class="more" @click="moreMusician">
				<Icon name="icon-7key-musicgengduoyinleren" theme-color="iconColorAll" width="16" height="16"></Icon>
				<div class="more-text">{{$t('moreMusic')}}</div>
			</div>
			<div class="nothing-text" v-if="(nothing == true) || ((numMusician < params.pageSize) && (numMusician > 0))">{{$t('nomore')}}</div>
		</div>
		<!-- 立即支付 弹窗 -->
		<!-- <PayImmediatelyPopup ref="popupPay" :orderInfo="orderInfo" :payInfo="payInfo"></PayImmediatelyPopup> -->
		
		<!-- 锁定demo 弹窗 -->
		<buySubscribePopup ref="lockSongPopup" :orderInfo="orderInfo" :payInfo="payInfo"></buySubscribePopup>
		
		<!-- 立即购买弹窗 -->
		<!-- <lockDemoPopup ref="buySongPopup" :orderInfo="orderInfo" :payInfo="payInfo"></lockDemoPopup> -->
	</div>
</template>

<script>
	import BGMDemo from "@/components/content/BGMDemo.vue"
	import SelectSearchTabs from "@/components/global/SelectSearchTabs";
	import buySubscribePopup from "@/components/popup/buySubscribePopup.vue"
	import PopupCommon from "@/components/popup/PopupCommon.vue"
	import BuySong from "@/components/popup/BuySong.vue";
	
	// import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
	// import PayImmediatelyTop from "@/components/content/PayImmediatelyTop.vue"
	// import Icon from "@/components/global/Icon";
	// import PayImmediatelyPopup from "@/components/popup/PayImmediatelyPopup.vue"
	import {
		bgmList,
		LockPrice,
		musicInfo,
		logo,
	} from "@/api/Home";
	import {
		resetLeftSearchTag
	} from "@/utils/common/store";
	import {
		getLiking,
		getLiked
	} from "@/api/Mine";

	export default {
		props: {},
		data() {
			return {
				demoType: '', //1海外 2内地
				visible: false,
				loading: true,
				isEmpty: false,
				DemoseaList: [{
					titlecontent: '认领伴奏'
				}],
				params: {
					page: 1, //页数
					pageSize: 10,
					min_bmp: '', //最小bmp
					max_bmp: '', //最大bmp
					bmp: '', //节拍(精准)
					key: '', //曲调id
					instruments: '', //乐器id
					genresId: '', //风格id
					moodId: '',//情绪id
					title: '', //搜索标题
					type: '', //搜索方式:1:歌曲名称搜索;2:制作人搜索; title 为搜索内容
					// recommend: 1, //1海外 2内地
					suit: '' // 歌手
				},
				nothing: false,
				numMusician: '',
				orderInfo: {
					avatar: '',
					name: '',
					musicId: '',
					// authorizedTerritory: '大陆',
					// authorizationType: '灌录权（灌录权、传播权、复制权）',
					orderSn: '',
					orderMoney: '',
					timeList: [{
							time: '3'
						},
						{
							time: '5'
						},
						{
							time: '17'
						}
					]
				},
				payInfo: {
					codeImg: '',
					time: 10 * 60,
					money: '',
					isWechat: '1', //是否支持微信支付 0不支持 1支持
					isApliy: '1', //是否支持支付宝支付 0不支持 1支持
					isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
					corporateName: this.$t("namecomponent") + this.$t("tranfercomponentinfo"),
					bankDeposit: this.$t("bankaccountinfo"),
					bankAccount: '21050110084600000051'
				
				},
				themeStyle: '', //主题 default浅色 dark深色
				musicInfoBuy: {}, //购买弹窗音乐信息
				price: '',
				discountPrice: '',
				lockPrice: '',
				lang: '',
			}
		},
		components: {
			BGMDemo,
			SelectSearchTabs,
			buySubscribePopup,
			// PayImmediatelyPopup,
			PopupCommon,
			BuySong
		},
		computed: {
			pages() {
				return this.params.page
			}
		},
		created() {
			this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : this.$i18n.locale
			this.resetLeftSearchTag()
			this.getMusicList(); //首页demo音乐列表--海外
			this.getLogoImg();//获取logo图片
			this.getLockPrice();// 预约价格锁定表
			
		},
		mounted() {},
		watch: {
			pages: {
				handler(newValue, oldValue) {
			
					console.log('page', newValue)
					this.params.page = newValue
					if (this.params.page == Math.ceil(this.numMusician / this.params.pageSize)) {
						setTimeout(() => {
							this.nothing = true
						}, 1000)
			
					}
					// this.themeName = newValue
					// console.log(this.GlobalCSS[newValue])
					// this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
				}
			},
			"$store.state.searchSingerList": {
				handler(newValue, oldValue) {
					console.log('歌手变化:', newValue)
					let searchValue = []
					let suitArr = this.$store.state.tagsList.suit_arr
					for (let i = 0; i < newValue.length; i++) {
						for (const key in suitArr) {
							if (newValue[i] == suitArr[key]) {
								searchValue.push(key)
							}
						}
					}
					this.params.suit = searchValue.join(',')
					this.getMusicList(true)
				}
			},
			"$store.state.searchStyleList": {
				handler(newValue, oldValue) {
					console.log('风格变化:', newValue)
					let searchValue = []
					// let genreArr = this.$store.state.tagsList.genre_arr
					// console.log('genreArr:', genreArr)
					// let newGenreArr = []
					// let styleOption = {}
					let genreArr = this.$store.state.tagsList.bgm_genre_arr
					console.log('genreArr:', genreArr)
					for (let i = 0; i < newValue.length; i++) {
						for (const key in genreArr) {
							console.log(newValue[i],genreArr[key],'genreArr[key]')
							if(this.lang == 'cn'){
								if (newValue[i] == genreArr[key].name) {
									searchValue.push(genreArr[key].id)
								}
							}else {
								if (newValue[i] == genreArr[key].ko_name) {
									searchValue.push(genreArr[key].id)
								}
							}
							
						}
					}
					// this.params.suit = searchValue.join(',')
					// this.getMusicList(true)
					// for (let i = 0; i < genreArr.length; i++) {
					// 	styleOption.id = genreArr[i].id
					// 	styleOption.name = genreArr[i].name
					// 	newGenreArr.push(JSON.parse(JSON.stringify(styleOption)))
					// 	// if (genreArr[i].child) {
					// 	// 	for (let j = 0; j < genreArr[i].child.length; j++) {
					// 	// 		styleOption.id = genreArr[i].child[j].id
					// 	// 		styleOption.name = genreArr[i].child[j].name
					// 	// 		newGenreArr.push(JSON.parse(JSON.stringify(styleOption)))
					// 	// 	}
					// 	// }
					// }
					// console.log('newGenreArr:', newGenreArr)
					// for (let n = 0; n < newValue.length; n++) {
					// 	// searchValue.push(newValue[n].id)
					// 	for (let k = 0; k < newGenreArr.length; k++) {
					// 		if (newValue[n] == newGenreArr[k].name) {
					// 			searchValue.push(newGenreArr[k].id)
					// 		}
					// 	}
					// }
					console.log(searchValue)
					this.params.genresId = searchValue.join(',')
					this.getMusicList(true)
				}
			},
			"$store.state.searchMusicalInstrumentsList": {
				handler(newValue, oldValue) {
					console.log('乐器变化:', newValue)
					let searchValue = []
					let instrumentArr = this.$store.state.tagsList.instrument_arr
					for (let i = 0; i < newValue.length; i++) {
						for (let j = 0; j < instrumentArr.length; j++) {
							// if (newValue[i] == instrumentArr[j].name) {
							// 	searchValue.push(instrumentArr[j].id)
							// }
							if(this.lang == 'cn'){
								if (newValue[i] == instrumentArr[j].name) {
									searchValue.push(instrumentArr[j].id);
								}
							}else {
								if (newValue[i] == instrumentArr[j].ko_name) {
									searchValue.push(instrumentArr[j].id);
								}
							}
						}
					}
					this.params.instruments = searchValue.join(',')
					this.getMusicList(true)
				}
			},
			"$store.state.searchMoodList": {
				handler(newValue, oldValue) {
					console.log('情绪变化:', newValue)
					let searchValue = []
					let moodArr = this.$store.state.tagsList.bgm_mood_arr
					for (let i = 0; i < newValue.length; i++) {
						for (let j = 0; j < moodArr.length; j++) {
							if (newValue[i] == moodArr[j].name) {
								searchValue.push(moodArr[j].id)
							}
						}
					}
					this.params.moodId = searchValue.join(',')
					this.getMusicList(true)
				}
			},
			"$store.state.searchSpeedList": {
				handler(newValue, oldValue) {
					console.log("速度变化:", newValue);
					let speedValue = newValue[0].split("-")
					console.log(speedValue,'speedValue')
					this.params.min_bmp = speedValue[0];
					this.params.max_bmp = speedValue[1];
					this.getMusicList(true)
				},
			},
		},
		methods: {
			resetLeftSearchTag,
			moreMusician(){
				// if(Math.ceil(this.numMusician / this.pageSize))
				// console.log(Math.ceil(this.numMusician / this.pageSize))
				console.log(this.params.page,Math.ceil(this.numMusician / this.params.pageSize),',,,,,,,,,,')
				if(this.params.page < Math.ceil(this.numMusician / this.params.pageSize)){
					this.params.page = this.params.page + 1
					this.getMusicList(false)
				}
				
			},
			lockSong(data) {
				console.log(data, 'data')
				// this.orderInfo.musicId = data.musicId
				this.themeStyle = localStorage.getItem("theme")
				this.orderInfo.avatar = data.image
				this.orderInfo.name = data.title
				this.orderInfo.orderSn = data.order_id
				this.orderInfo.musicId = data.id
				// this.orderInfo.orderMoney = res.data.
				this.$refs.lockSongPopup.open()
			},
			// 获取logo图片
			getLogoImg() {
				logo()
					.then((res) => {
						// console.log(res, '获取logo图片');
						this.payInfo.codeImg = res
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			// 预约价格锁定表
			getLockPrice() {
				LockPrice()
					.then((res) => {
						// console.log(res, '预约价格锁定表');
						this.orderInfo.timeList = res.data.list
					})
					.catch((err) => {
						// console.log(err, '获取banner失败');
					});
			},
			// 立即支付弹窗
			buyImmidately(data) {
				// console.log(data, type, 'mmmmmmmmmmmmmmmmmmmm')
				// this.demoType = type
				console.log(data, 'jjsadjaodsdosajkdaskdjsdjksakjdkj')
				this.themeStyle = localStorage.getItem("theme")
				// this.$refs.popupPay.open()
				this.musicInfoBuy = data
				this.$refs.popupbuy.open()
			},
			getdiscountPrice(data) {
				console.log(data, 'getdiscountPrice')
				this.discountPrice = data.discountPrice
				this.couponId = data.discountValue
			},
			// 获取锁定价格
			getlockPrice(data) {
				// console.log(data,'获取锁定价格')
				this.lockPrice = data
			},
			getPrice(data) {
				// console.log(data,'data')
				this.price = data
			
			},
			getBuyType(data) {
				// console.log(data,'buyType')
				this.buyType = data
			},
			areaType(data) {
				// console.log(data,'areaType')
				this.orderInfo.authorizedTerritory = data
				this.authorizationArea = data
			},
			changeTime(data) {
				// console.log(data,'changeTime')
				this.yearId = data
			},
			authType(data) {
				// console.log(data,'authType')
				this.authorizationType = data.name
				this.orderInfo.authorizationType = data.name + '('
				let arr = data.details
				// arr.map(item => {
				// 	console.log(item,'isisisissisi')
				// 	// this.orderInfo.authorizationType += item.name
				// })
				for (var key in arr) {
					// console.log(key,'iiiiiiiiiiiiiiiiiii')
					this.orderInfo.authorizationType = this.orderInfo.authorizationType + arr[key].name
					// console.log(this.orderInfo.authorizationType)
				}
				this.orderInfo.authorizationType = this.orderInfo.authorizationType + ')'
			},
			//购买单个音乐
			buyMusic() {
				let params = {};
				params.musicId = this.musicInfoBuy.musicId; //音乐id
				params.payType = this.payType; //支付类型
				params.authorizationType = this.authorizationType; //授权类型
				params.authorizationRegion = this.authorizationArea; //授权地区
				params.authorizationDuration = this.yearId; //授权时间
				// params.lrcId = cId;
				params.couponId = this.couponId;
				params.voucher_img = this.voucherImage;
				params.lockPrice = this.lockPrice;
				if(this.demoType == 2){
					params.is_beat = this.buyType;
				}
				// console.log(params,'paramsparams');
				buyMusic(params).then((res) => {
					if (res.status != 1) {
						this.$message({
							type: "error",
							message: res.msg,
						});
						return;
					}
					if (this.payType == 4) {
						this.$message({
							type: "success",
				   message: this.$t("orderhascreate"),
						});
				 }
					this.payInfo.codeImg = res.data.qr_code;
					this.orderInfo.orderSn = res.data.order_id;
					// let data = {};
					// data.order_id = res.data.order_id;
					// this.$refs.popupTransfer.close()
					// let test = setInterval(() => {
					//   getCheckOrder(data).then((res) => {
					//     if (!(res.status === -1)) {
					//       this.$message({
					//         type: "success",
					//         message: "支付成功",
					//       });
					//       clearInterval(test);
					// this.$refs.popupTransfer.close()
					//       // this.handleClose_cart();
					//       // this.$router.push("/personal/mypurchased");
					//     }
					//   });
					// }, 1000);
				});
			},
			buyMusic1() {
				let data = {};
				data.order_id = this.orderInfo.orderSn;
				// this.$refs.popupTransfer.close()
				getCheckOrder(data).then((res) => {
					if (!(res.status === -1)) {
				  this.$message({
							type: "success",
							message: this.$t("paysuccess"),
						});
						this.$refs.popupTransfer.close()
						this.$router.push("/personalCenter/MyPurchase/Purchased");
						// this.handleClose_cart();
						// this.$router.push("/personal/mypurchased");
					}
				});
			},
			// 获取支付凭证
			getvoucherImage(data) {
				this.voucherImage = data
			},
			// 获取支付类型
			getPayType(data) {
				console.log(data, 'wqwerttyyuiooppp[[[]]]')
				this.payType = data
			},
			// 立即支付弹窗 客服
			payCustomerService() {
				this.$refs.popupPay.close()
			},
			// 立即支付弹窗 完成支付
			payFinishPay() {
				// this.$refs.popupbuy.close();
				// this.getLogoImg()
				this.$refs.popupTransfer.open()
			
				// console.log(this.musicInfo,'完成支付')
				this.orderInfo.avatar = this.musicInfoBuy.image
				this.orderInfo.name = this.musicInfoBuy.title
				this.orderInfo.orderMoney = this.price
				this.payInfo.money = this.price
			},
			// 添加喜欢
			liking(data) {
				console.log(data, 'dtata')
				getLiking({
						musicId: data,
						isBgm: 1
					}).then((res) => {
						console.log(res, '添加喜欢成功');
						if (res.status == 1) {
							this.$message.success(res.msg)
							this.getMusicList()
						} else {
							this.$message.error(res.msg)
						}
						console.log(type, 'type')
					})
					.catch((err) => {
						console.log(err, '添加喜欢失败');
					});
			},
			// 取消喜欢
			cancelLike(data) {
				getLiked({
						musicId: data,
						isBgm: 1
					}).then((res) => {
						console.log(res, '取消喜欢成功');
						if (res.status == 1) {

							this.$message.success(res.msg)
							this.getMusicList()
						} else {
							this.$message.error(res.msg)
						}

					})
					.catch((err) => {
						console.log(err, '取消喜欢失败');
					});
			},
			// 首页demo音乐列表
			getMusicList(status) {
				if (status) {
					this.params.page = 1
					this.DemoseaList = []
				}
				bgmList(this.params).then((res) => {
						this.loading = false
						console.log(res, '列表成功');
						this.numMusician = res.data.count
						if(this.params.page == 1){
							let arr = []
							arr = res.data.list.map(item => {
								return {
									...item,
									titlecontent: '认领伴奏'
								}
							
							})
							this.DemoseaList = arr
						}else {
							let arr = []
							arr = res.data.list.map(item => {
								return {
									...item,
									titlecontent: '认领伴奏'
								}
							
							})
							this.DemoseaList = this.DemoseaList.concat(arr);
						}
						if(this.DemoseaList == ''){
							this.isEmpty = true
						}else {
							this.isEmpty = false
						}
						
					})
					.catch((err) => {
						console.log(err, '首页成品音乐列表失败');
					});
			},
		},


	}
</script>

<style scoped lang="less">
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
	.out-back-demo {
		overflow: auto;
		// margin-top: 10px;
		.listbox {
			.emptyView{
				width: 100%;
				display: flex;
				justify-content: center;
				min-height: 100px;
			}
		}
		.buyPopup{
			.pop-title {
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid @demoitemtabsBGColor;
				padding: 0 25px;
				color: @recommendMusicianListTextColor;
			}
			.pop-contentbuy {
				height: 600px;
				padding: 24px;
				border-bottom: 1px solid rgba(34, 36, 38, 0.06);
				padding: 25px;
				color: @recommendMusicianListTextColor;
			}
			.pop-footerbuy-right {
				display: flex;
			
				.cancelbuy {
					// width: 102px;
					// height: 36px;
					border-radius: 4px;
					border: 1px solid @recommendMusicianListTextColor;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: @popupCancelTextColor;
					margin-right: 44px;
					background: @popupCancelBgColor;
					width: 40px;
					height: 36px;
					border-radius: 4px;
					cursor: pointer;
			
					// border: 1px solid #FFFFFF;
					img {
						width: 16px;
						height: 16px;
					}
			
					&:hover {
						// border: 1px solid @recommendMusicianListTextColor;
						position: relative;
			
						&:after {
							position: absolute;
							content: '联系版权客服';
							left: -50%;
							bottom: -30px;
							background: red;
							color: green;
							width: 92px;
							height: 27px;
							background: rgba(255, 255, 255, 0.7);
							box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
							border-radius: 4px;
							font-size: 12px;
							color: #222426;
							line-height: 27px;
							display: flex;
							justify-content: center;
						}
					}
				}
			
				.confirmbuy {
					width: 102px;
					height: 36px;
					background: #3278ff;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #ffffff;
					cursor: pointer;
					margin-right: 20px;
				}
			}
		}
	}

	.out-back-demo::-webkit-scrollbar {
		display: none;
	}
	.more-wrap {
		display: flex;
		width: 100%;
		justify-content: center;
		// margin-top: 20px;
		margin-top: -60px;
	
		.more {
			width: 190px;
			height: 36px;
			border-radius: 6px;
			border: 1px solid @recommendMusicianListTextColor;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
	
			.more-text {
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: @recommendMusicianListTextColor;
				line-height: 20px;
				margin-left: 10px;
			}
		}
		.nothing-text{
			color: @recommendMusicianListTitleColor;
		}
	}
</style>

