<template>
  <div class="select-search-tabs">
    <div class="tagbox">
      <!-- 歌手 -->
      <div>
        <div v-for="(item, index) in $store.state.searchSingerList" :key="index">
          <div class="tag">
            <div class="tag-name">{{ item }}</div>
            <div class="tag-detele" @click="deteletag(1, item)">
              <Icon
                name="icon-7key-musicjianhao"
                theme-color="iconColorAll"
                width="13"
                height="13"
              ></Icon>
            </div>
          </div>
        </div>
      </div>

      <!-- 风格 -->
      <div style="display: flex">
        <div v-for="(item, index) in $store.state.searchStyleList" :key="index">
          <div class="tag">
            <div class="tag-name">{{ item }}</div>
            <div class="tag-detele" @click="deteletag(2, item)">
              <Icon
                name="icon-7key-musicjianhao"
                theme-color="iconColorAll"
                width="13"
                height="13"
              ></Icon>
            </div>
          </div>
        </div>
      </div>
      <!-- 乐器 -->
      <div>
        <div
          v-for="(item, index) in $store.state.searchMusicalInstrumentsList"
          :key="index"
        >
          <div class="tag">
            <div class="tag-name">{{ item }}</div>
            <div class="tag-detele" @click="deteletag(3, item)">
              <Icon
                name="icon-7key-musicjianhao"
                theme-color="iconColorAll"
                width="13"
                height="13"
              ></Icon>
            </div>
          </div>
        </div>
      </div>
      <!-- 情绪 -->
      <div>
        <div v-for="(item, index) in $store.state.searchMoodList" :key="index">
          <div class="tag">
            <div class="tag-name">{{ item }}</div>
            <div class="tag-detele" @click="deteletag(4, item)">
              <Icon
                name="icon-7key-musicjianhao"
                theme-color="iconColorAll"
                width="13"
                height="13"
              ></Icon>
            </div>
          </div>
        </div>
      </div>
      <!-- 速度 -->
      <div>
        <div v-for="(item, index) in $store.state.searchSpeedList" :key="index">
          <div class="tag">
            <div class="tag-name">{{ item }}</div>
            <div class="tag-detele" @click="deteletag(5, item)">
              <Icon
                name="icon-7key-musicjianhao"
                theme-color="iconColorAll"
                width="13"
                height="13"
              ></Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {
	  console.log(this.$store.state.searchSpeedList,'$store.state.searchSpeedList  created')
  },
  mounted() {},
  watch: {},
  methods: {
    deteletag(val, type) {
      switch (val) {
        case 1:
          let arr = this.$store.state.searchSingerList.filter((item) => {
            console.log(item);
            return item != type;
          });
          console.log(arr);
          this.$store.commit("changeSingerValue", arr);
          break;
        case 2:
          //这里是值对应的处理
          console.log(this.$store.state.searchStyleList);
          let arr1 = this.$store.state.searchStyleList.filter((item) => {
            console.log(item);
            return item != type;
          });
          console.log(arr1);
          this.$store.commit("changeStyleValue", arr1);
          break;
        case 3:
          let arr2 = this.$store.state.searchMusicalInstrumentsList.filter(
            (item) => {
              console.log(item);
              return item != type;
            }
          );
          console.log(arr2);
          this.$store.commit("changeMusicalInstrumentsValue", arr2);
          break;
        case 4:
           let arr3 = this.$store.state.searchMoodList.filter(
            (item) => {
              console.log(item);
              return item != type;
            }
          );
          console.log(arr3);
          this.$store.commit('changeMoodValue', arr3)
          break;
        case 5:
           let arr4 = this.$store.state.searchSpeedList.filter(
            (item) => {
              console.log(item);
              return item != type;
            }
          );
          console.log(arr4);
          this.$store.commit('changeSpeedValue', arr4)
          break;
      }
    },
  },
};
</script>

<style scoped lang='less'>
.select-search-tabs {
  display: flex;
  margin-bottom: 20px;
}
.tagbox {
  width: 100%;
  height: 20px;
  display: flex;
  margin-top:10px ;
}
.tag {
  // width: 82px;
  height: 30px;
  background: @recommendMusicianListBGColor;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  padding: 1px 10px 0px 10px;
  .tag-name {
    // width: 36px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: @headerSelectTextColor;
    line-height: 17px;
  }
  .tag-detele {
    margin-left: 5px;
  }
}
</style>
